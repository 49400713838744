<template>
  <div class="pdf-wrapper">
    <div class="pdf-content">
    <div class="pdf-contain">
      <pdf
        :key="pdfUrl"
        :page="pageNum"
        :src="pdfUrl"
        class="pdf-show"
        @error="pdfError($event)"
        @loaded="loaded"
        @progress="loadedRatio = Number((($event||0)*100).toFixed(0))"
        @page-loaded="pageLoaded($event)"
        @num-pages="pageTotalNum = $event"
        @link-clicked="clickPage = $event"
      />
    </div>
    
    <div class="paging-box">
      
      <div class="paging-subtitle">{{fileNames}}.pdf</div>

      <!-- 按钮部分 -->
      <div class="tools">
          
          <el-button size="mini" @click="prePage">
            上一页
          </el-button>

          <div v-if="curPageNum&&pageTotalNum" class="numBox">
            <span style="color:#fff;">{{ curPageNum }}</span>
            /
            <span style="color: #7B7B7B">{{ pageTotalNum }}</span>
          </div>
          <!-- 页码 -->
          <el-input v-model.number="jumpPage" placeholder="输入页码" size="mini" style="width: 150px;">
            <el-button slot="append" @click="jumpPageHandler">跳转</el-button>
          </el-input>
          <el-button  size="mini" @click="nextPage">
            下一页
          </el-button>
        </div>
    </div>
  </div>
 </div>
</template>

<script>
import pdf from 'vue-pdf'
export default {
  name: 'listPdf',
  components: {
    pdf
  },
  
  data() {
    return {
      laborList: {},
      //总页数
      pageTotalNum: 1,
      //当前pdf文件
      pdfUrl: '',
      //当前展示pdf的第几页
      pageNum: 1,
      // 加载进度
      loadedRatio: 0,
      processShow: true,
      //当前展示页数
      curPageNum: 0,
      numPages: 0,
      //当前点击的页码
      clickPage: '',
      //想要跳转的页码
      jumpPage: '',
      feilUrl:'',
      fileNames:''
    }
  },
  created() {
  },
  mounted(){
    // if(sessionStorage.getItem("laborList")){
    //   this.laborList = JSON.parse(
    //     sessionStorage.getItem("laborList")
    //   );
    //   this.getNumPages(this.laborList.pdfSrc)
    // }
    this.feilUrl = this.$route.query.feilUrl;
    this.fileNames = this.$route.query.fileNames;
    console.log('8888',this.feilUrl);
    this.getNumPages(this.feilUrl)
    
  },
  methods: {
    getNumPages(url) {
      var loadingTask = pdf.createLoadingTask(url)
      loadingTask.promise.then(pdf => {
          this.pdfUrl = loadingTask
          this.numPages = pdf.numPages
          this.pageTotalNum = pdf.numPages
          console.log(this.pageTotalNum, 'pageTotalNum')
      }).catch((err) => {
          console.error('pdf加载失败')
      })
    },
    loaded() {
      console.log('加载完成')
      // this.$message({
      //   message: '文件加载完成！',
      //   type: 'success'
      // });
      setTimeout(() => {
        this.processShow = false
      }, 100)
    },
    jumpPageHandler() {
      console.log('topage' + this.jumpPage)
      if (this.jumpPage > 0 && this.jumpPage < this.pageTotalNum) {
        this.pageNum = this.jumpPage
      } else {
        this.$message({
          message: '请输入正确范围的页数！',
          type: 'warning'
        })
      }
      this.jumpPage = ''
    },
    // 上一页函数，
    prePage() {
      let page = this.pageNum
      page = page > 1 ? page - 1 : this.pageTotalNum
      this.pageNum = page
    },
    // 下一页函数
    nextPage() {
      let page = this.pageNum
      page = page < this.pageTotalNum ? page + 1 : 1
      this.pageNum = page
    },
    // 页面加载回调函数，其中e为当前页数
    pageLoaded(e) {
      this.curPageNum = e
    },
    // 其他的一些回调函数。
    pdfError(error) {
      console.error(error)
    }
  }
}
</script>


<style lang="less" scoped>
.pdf-wrapper {
  width: 100%;
  // height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
}
 
.pdf-wrapper span {
  width: 100%;
}


 
.pdf-content {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow-x: hidden;
  
  
  
 
  .pdf-contain {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    background: #12111180;
    padding: 20px 0;
    overflow-y: auto;
 
    .pdf-show {
      margin: 0 auto;
      max-width: 40%;
      height: 650px;
      padding-bottom: 20px;
    }
  }

  .paging-box{
    width: 100%;
    background: rgb(50, 54, 57);
    display: flex;
    
    .paging-subtitle{
      color:#fff;
      padding: 12px 0 0 20px;
    }
    .tools {
      width: 36vw;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      z-index: 999;
      margin-left: 11%;
      text-align: center;
      

      .numBox {
        margin: 10px 20px;
        font-size: 16px;
      }
    }
    
  }
}
 
.pdf-button {
  width: 240px;
  height: 60px;
  font-size: 30px;
  border-radius: 20px;
}
 
.pdf-button:hover {
  cursor: pointer;
  transform: scale(1.2);
}
 
.page {
  font-size: 48px;
  color: #fff;
}
 
.loadingi {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
