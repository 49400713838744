<template>
  <div>
    <div id="iframeBox"></div>
  </div>
</template>

<script>
export default {
  name: 'listpptx',
  components: {},
  data() {
    return {
      laborList: {},
      wdUrl:'',
      wdType:'',
      wdId:''
    }
  },
  mounted(){
    this.wdUrl = this.$route.query.url;
    
    console.log('5555',this.wdUrl);
    this.wdId = this.$route.query.wdId;
    // this.wdUrl = 'https://microred.oss-cn-hangzhou.aliyuncs.com/micro-red-health-app/1653037004631553459.pdf'
    this.wdType = this.wdUrl.substring(this.wdUrl.lastIndexOf(".")+1);//截取文件后缀 pdf,doc,docx
    document.title = `${this.$route.query.title}.${this.wdType}`
    this.preview(this.wdType,this.wdUrl)
    
    //1、判断浏览器是否支持popstate
    if (window.history && window.history.pushState) {
        console.log('支持popstate')
        history.pushState(null, null, document.URL);
        window.addEventListener('popstate', this.indexGoBack, false);//false阻止默认事件
    }
    
  },
  //2、页面销毁时，取消监听。否则其他vue路由页面也会被监听  
  destroyed(){
      window.removeEventListener('popstate', this.indexGoBack, false);//false阻止默认事件
  },
  methods: {
    preview(valueFormat,url){
      //动态创建iframe
      var iframe = document.createElement('iframe');
      iframe.id = "modifyIframe";
      //先进行类型判断
      if(valueFormat=='doc'|| valueFormat=='docx'|| valueFormat=='xls' || valueFormat=='xlsx' || valueFormat=='pptx' || valueFormat=='ppt') {//根据后缀判断如果
        iframe.src = 'https://view.officeapps.live.com/op/view.aspx?src='+ encodeURIComponent(url);
        console.log('src',iframe.src);
      }else{ //如果是pdf
        iframe.src = url;
      }
      iframe.width = '100%';
      iframe.height = window.innerHeight - 5 + "px";
      // iframe.height ='100%';
      iframe.style.maxHeight = '1000px';
      let iframeBox = document.getElementById("iframeBox");
      iframeBox.appendChild(iframe);
    },
    //监听返回按钮，销毁iframe
    indexGoBack(){
        this.$router.go(-1)
        let iframe = document.getElementById("modifyIframe");
        iframe.src = "about:blank";
        iframe.contentWindow.document.write('')
        iframe.contentWindow.document.clear();
        iframe.contentWindow.close();
        iframe.parentNode.removeChild(iframe);
        iframe = null;
    },
  },
}
</script>

<style lang="less" scoped>
</style>
