<template>
  <div id="app">
    <!-- <router-view /> -->
    <keep-alive>
        <router-view v-if="$route.meta.keepAlive" />
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* padding: 10px 30px; */
  font-size: 16px;
}
body{
  margin: 0;
  overflow: hidden;
  /* overflow-x: hidden; */
}

</style>
