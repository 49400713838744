<template>
  <div class="excel-box">
    <div>
      <input style="display: none;" id="import" type="file" accept=".xls,.xlsx" name="12"/>
      <label for="import" style="cursor: pointer;" class="i18n" i18nName="Import"></label>
      <a href="./file/importTemplate.xlsx" style="color: #000;" class="i18n" i18nName="Export"></a>
  </div>
  <!-- <div class="title-box">
    <div class="subtitle">测试表格.xlxs</div>
  </div> -->
  <el-table :data="columnList" border
      style="width: 100%;height: calc(100vh - 10px);">
      <el-table-column
      type="index"
      width="50">
    </el-table-column>
      <el-table-column
        prop="A"
        label="A">
      </el-table-column>
      <el-table-column
        prop="B"
        label="B">
      </el-table-column>
      <el-table-column
        prop="C"
        label="C">
      </el-table-column>
      <el-table-column
        prop="D"
        label="D">
      </el-table-column>
      <el-table-column
        prop="E"
        label="E">
      </el-table-column>
      <el-table-column
        prop="F"
        label="F">
      </el-table-column>
      <el-table-column
        prop="G"
        label="G">
      </el-table-column>
      <el-table-column
        prop="I"
        label="I">
      </el-table-column>
      <el-table-column
        prop="J"
        label="J">
      </el-table-column>
      <el-table-column
        prop="K"
        label="K">
      </el-table-column>
      <el-table-column
        prop="L"
        label="L">
      </el-table-column>
      <el-table-column
        prop="M"
        label="M">
      </el-table-column>
      <el-table-column
        prop="N"
        label="N">
      </el-table-column>
      <el-table-column
        prop="O"
        label="O">
      </el-table-column>
      <el-table-column
        prop="P"
        label="P">
      </el-table-column>
      <el-table-column
        prop="Q"
        label="Q">
      </el-table-column>
      <el-table-column
        prop="R"
        label="R">
      </el-table-column>
      <el-table-column
        prop="S"
        label="S">
      </el-table-column>
      <el-table-column
        prop="T"
        label="T">
      </el-table-column>
      <el-table-column
        prop="U"
        label="U">
      </el-table-column>
      <el-table-column
        prop="V"
        label="V">
      </el-table-column>
      <el-table-column
        prop="W"
        label="W">
      </el-table-column>
      <el-table-column
        prop="X"
        label="X">
      </el-table-column>
      <el-table-column
        prop="Y"
        label="Y">
      </el-table-column>
      <el-table-column
        prop="Z"
        label="Z">
      </el-table-column>
      <el-table-column
        prop="AA"
        label="AA">
      </el-table-column>
      <el-table-column
        prop="AB"
        label="AB">
      </el-table-column>
      <el-table-column
        prop="AC"
        label="AC">
      </el-table-column>
      <el-table-column
        prop="AD"
        label="AD">
      </el-table-column>
      <el-table-column
        prop="AE"
        label="AE">
      </el-table-column>
      <el-table-column
        prop="AF"
        label="AF">
      </el-table-column>
      <el-table-column
        prop="AG"
        label="AG">
      </el-table-column>
      <el-table-column
        prop="AH"
        label="AH">
      </el-table-column>
      <el-table-column
        prop="AI"
        label="AI">
      </el-table-column>
      <el-table-column
        prop="AJ"
        label="AJ">
      </el-table-column>
    </el-table>
    <!-- <div @click="xz">下载</div> -->
    
  </div>
</template>

<script>
import * as XLSX from 'xlsx'


// import * as XLSX_STYLE from 'xlsx-style' //样式
import * as XLSX_STYLE from 'xlsx-style'
export default {
  name: 'listExcel',
  components: {
    // XLSX
  },
  
  data() {
    return {
      laborList: {},
      table:'',
      columnList:[],
      openExcelList:''
    }
  },
  created() {
  },
  mounted(){
    if(sessionStorage.getItem("laborList")){
      this.laborList = JSON.parse(
        sessionStorage.getItem("laborList")
      );
      this.createpsbgDoc(this.laborList.pdfSrc)
    }
    
  },
  methods: {
    createpsbgDoc(data){
      let _this = this;
      this.$http.get('api/readXlsx',{responseType: 'blob'}).then(res => {//处理响应
        console.log('这是xlsx',res);
        let blob = new Blob([res.data], {type:"application/vnd.openxmlformats-officedocument.wordprocessingml.document"});
        
        var imgFile = new window.File([blob], Math.random(), { //把bolb转成file文件
          type: blob.type,
        });
        this.parseExcel(imgFile).then(function(result){
          _this.openExcelList = result;
          for(var i=0; i<result.length;i++){
            // 经过xlsx处理过的数据，按需处理渲染到table里。
            _this.columnList.push({
              A: result[i].A || '',
              B:result[i].B || '',
              C:result[i].C || '',
              D:result[i].D || '',
              E:result[i].E || '',
              F:result[i].F || '',
              G:result[i].G || '',
              H:result[i].H || '',
              I:result[i].I || '',
              J:result[i].J || '',
              K:result[i].K || '',
              L:result[i].L || '',
            });
          }
        })
      }).catch(error => {//处理错误
        console.log(error);
      });
      
    },
    parseExcel(file) {
      return new Promise(function (resolve, reject) {
          var reader = new FileReader();
          var result=[];
          reader.onload = function (e) {
            console.log('e',e);
              var data = e.target.result;
              var workbook = XLSX.read(data, { type: 'binary' });
              console.log('workbook',workbook.Sheets);
              workbook.SheetNames.forEach(function (sheetName) {
                  var XL_row_object = XLSX.utils.get_formulae(workbook.Sheets[sheetName]);
                  console.log(XL_row_object)
                  var currentRowNumber = 1;
                  var currentRow = {};
                  for (var i = 0; i < XL_row_object.length; i++) {
                      var temp = XL_row_object[i];//A1='a
                      var column = (temp.split("=")[0] + '') || '';//A1
                      var value = (temp.split("=")[1] + '') || '';//a
                      var rowNumber = parseInt(column.replace(/[^0-9]+/g,''));//1
                      var columnName = column.replace(/[^a-zA-Z]+/g,'');//A
                      if (currentRowNumber == rowNumber) {
                          currentRow[columnName] = value;
                      } else {
                          result.push(currentRow);
                          currentRowNumber = rowNumber;
                          currentRow = {};
                          currentRow[columnName] = value;
                      }
                  }
                  console.log('result',result);
                  if (XL_row_object.length > 0)
                      result.push(currentRow);
              });
              resolve(result);
          };

          reader.onerror = function (ex) {
              reject(new Error(ex));
          };

          reader.readAsBinaryString(file);
      });
    },
    // xz(){
    //   console.log(this.openExcelList);
    //   var wb = XLSX.utils.book_new();
 
    //   var ws_name = "SheetJS";
      
    //   /* make worksheet */
      
    //   var ws_data = [
      
    //       // [1, 2, 3, 4, 5]
    //       ["'啊","1","2","3"]
      
    //   ];
      
    //   console.log(ws_data);
    //   var ws = XLSX.utils.aoa_to_sheet(ws_data);
      
    //   /* Add the worksheet to the workbook */
      
    //   XLSX.utils.book_append_sheet(wb, ws, ws_name);
      
    //   XLSX.writeFile(wb, "test.xlsx")
    // }
  },
}
</script>

<style lang="less" scoped>
 .title-box{
  background: #EEEEEE;
  padding: 1px;
    .subtitle{
      background: #fff;
      margin: 10px 10px;
      padding: 10px 20px;
      border-radius: 10px;
      border: 1px solid #ccc;
    }
 }
</style>

<style lang="less">
.excel-box .el-table--scrollable-x .el-table__body-wrapper{
  height: 95%;
}
.excel-box .has-gutter th{
  background: #EEEEEE;
  border-right: 1px solid #E6E6E6;
  font-weight: 500;
  color: #64595B;
  padding: 2px 0;
}
.excel-box .el-table__body .el-table_1_column_1{
  background: #EEEEEE;
  border-bottom: 1px solid #E6E6E6;
  font-weight: 500;
  color: #64595B;
}
</style>
