'use strict'
const path = require('path')

function resolve(dir) {
    return path.join(__dirname, dir)
}

// module.exports = {
//     // 在npm run build 或 yarn build 时 ，生成文件的目录名称（要和baseUrl的生产环境路径一致）（默认dist）
//     outputDir: 'dist',
//     // 用于放置生成的静态资源 (js、css、img、fonts) 的；（项目打包之后，静态资源会放在这个文件夹下）
//     assetsDir: 'static',
//     configureWebpack: {
//         resolve: {
//             alias: {
//                 '@': resolve('src')
//             }
//         }
//     },
//     // devServer: {
//     //     proxy: {
//     //         '/api': {
//     //             target: 'http://localhost:8080',
//     //             pathRewrite: { '^/api': '' }//路径改写
//     //         },

//     //         '/readfile': {
//     //             target: 'http://a.qulaxin.com',
//     //             pathRewrite: { '^/readfile': '' }//路径改写
//     //         }

//     //     }
//     // }
//     devServer: {
//         proxy: {
//           '/*': { //匹配路径
//             target: 'http://a.qulaxin.com',
//             changeOrigin: true// 允许websockets跨域
//             // ws: true,
//           }
//         }
//     }

//     // 请求地址路径加前缀
//     // http://a.qulaxin.com/readfile/readDoc
// }

// module.exports = {
//     publicPath: './',
//     //配置跨域请求
//     devServer: {
//         open: true,
//         // host: 'localhost',
//         host: '0.0.0.0',
//         port: 8080,
//         https: false,
//         proxy: {  //配置跨域
//           '/api': {
//             target: 'http://a.qulaxin.com',  //这里后台的地址模拟的;应该填写你们真实的后台接口
//             // target:'https://gs.wlrsj.wl.gov.cn:9443/ifs/woa/wl',
//             secure:false,
//             changeOrigin: true,//是否跨域
//             ws: true,//表示WebSocket协议
//             pathRewrite:{
//               /* 重写路径，当我们在浏览器中看到请求的地址为：/api/…… 时
//                 实际上访问的地址是：http://"你请求的后台地址.com/……",因为重写了 /api
//                */
//               '^/api': '/'
//             }
//           },
//         }
//       },
//    }

module.exports = {
    devServer: {
      open: true, //是否自动弹出浏览器页面
      host: "localhost", 
      port: 8080,
      // https: true,
      hotOnly: false, 
      proxy: {
          '/api': {
              target: 'http://a.qulaxin.com', 
              changeOrigin: true,//是否跨域
            // ws: true,//表示WebSocket协议
              pathRewrite: {  
                  '^/api': ''
              }
          },
          //有时候系统有多个不同的代理地址，我们可以加一份代理即可！
			'/test':{
				target: 'https://145164in59.zicp.fun',   //这里写真实的请求url
				ws:true,    //是否启用websockets
				changOrigin: true, //允许跨域 开启代理：在本地会创建一个虚拟服务端，然后发送请求的数据，并同时接收请求的数据，这样服务端和服务端进行数据的交互就不会有跨域问题
				pathRewrite: {    //重写代理路径
                    '^/test': ''  //如果你访问的是'http://192.168.1.1:8092/api/shop/car' ,则变为 'http://192.168.1.1:8092/shop/car'。浏览器控制台虽然看到的还是带有/test的，但是实际发给后端的地址是没有包含/test的
                }
			}
      },
  },
}