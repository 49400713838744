import Vue from 'vue'
import Router from 'vue-router'
import HomePage from '../src/views/homePage.vue'
import ListPdf from '../src/views/listPdf.vue'
import ListWord from '../src/views/listWord.vue'
import ListExcel from '../src/views/listExcel.vue'
import Listpptx from '../src/views/listpptx.vue'
import Ceshi from '../src/views/ceshi.vue'
import PlayVideo2 from '../src/views/playVideo2.vue'

Vue.use(Router);

const router = new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
	{
		path: '/',
		name: 'homePage',
		component: 	HomePage,
		meta: {
			title: 'HomePage预览',
			keepAlive: true
		},
	},{
		path: '/listPdf',
		name: 'listPdf',
		component: 	ListPdf,
		meta: {
			title: 'listPdf预览'
		}
	},{
		path:'/listWord',
		name: 'listWord',
		component: 	ListWord,
		meta: {
			title: 'Word预览'
		}
	},{
		path:'/listExcel',
		name: 'listExcel',
		component: 	ListExcel,
		meta: {
			title: 'Excel预览'
		}
	},{
		path:'/listpptx',
		name: 'listpptx',
		component: 	Listpptx,
		meta: {
			title: '文件预览'
		}	
	},{
		path:'/playVideo2',
		name: 'playVideo2',
		component: 	PlayVideo2,
		meta: {
			title: '视频播放'
		}	
	},
	// {
	// 		path: '/ceshi',
	// 		name: 'ceshi',
	// 		component: Ceshi,
	// 		meta: {
	// 			title: 'ceshi',
	// 		}
	// }
  ]
});



// 路由跳转后的钩子
router.afterEach((to, from, next) => {
if (to.meta.title) {
	document.title = to.meta.title;
}
})

router.meta = {
	isKeepAlive: false,
	isTabbar: true,
};

export default router
