<template>
  <div ref="epDialog" class="ep-dialog">
    <div class="pop-main">

      <div class="pop-container">
        <el-row>
          <el-col :span="24">
            <div class="input_video">
              <video-player
                class="video-player vjs-custom-skin"
                ref="videoPlayer"
                :playsinline="true"
                :options="playerOptions"
                @play="onPlayerPlay"
              ></video-player>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Siderbar",
  data() {
    return {
      // addDataList:[],
         // 视频播放
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], //可选择的播放速度
        autoplay: true, //如果true,浏览器准备好时开始回放。自动播放
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",//语言，'en', 'zh-cn', 'zh-tw'
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "",
            src:'',
            // src: "https://stream7.iqilu.com/10339/upload_transcode/202002/18/20200218114723HDu3hhxqIT.mp4", //url地址
          },
        ],
        poster: "", //你的封面地址
        // width: document.documentElement.clientWidth,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, //当前时间和持续时间的分隔符
          durationDisplay: true, //显示持续时间
          remainingTimeDisplay: false, //是否显示剩余时间功能
          fullscreenToggle: true, //全屏按钮
        },
      },
      btnText:'清晰度',
      vjsElement:'',
      itemIndex:0, //点击次数
      vjsControl:'',
      // playbackCount:'',//播放次数
      wdUrl:'',
      wdHighUrl:'',
      wdName:'',
      fileType:''
    }
  },
  
  computed: {},
  mounted() {
    this.fileType = this.$route.query.type;
    document.title = `${this.$route.query.title}.${this.fileType}`
    this.wdUrl = this.$route.query.url;//标清视频链接
    this.wdHighUrl = this.$route.query.high_url;//高清视频链接
    this.playerOptions.sources[0].src = this.wdUrl;
  },
  methods: {
    closeDialog() {
      this.$emit("close");
    },

    // 播放回调(播放时会调用)(用户操作调用)
    // onPlayerPlay (player) {
    //   console.log('播放', player)
    //   // this.$emit('onPlayerPlay', player)
    // },

    //添加切换清晰度 按钮
    // 监听播放
    onPlayerPlay(){
      this.$refs.videoPlayer.player.play() // 播放
      // 播放的时候判断一下 有没有 清晰度这个按钮
      // !this.$('#vjsControl')
        if(!document.getElementById('vjsControl')){
              this.addTool()
        }
    },
    addTool(){
      if(this.wdUrl && this.wdHighUrl){
        let that = this;
        this.vjsElement = this.$el.querySelectorAll(".vjs-control-bar")[0];
        var increase = document.createElement("div");
        increase.setAttribute("id", "vjsControl");
        increase.innerHTML = this.btnText;
        this.vjsElement.appendChild(increase); //添加清晰度按钮
        this.vjsControl = document.getElementById('vjsControl')
        this.vjsControl.addEventListener('click',that.resourceClcik)
      }
    },
    // 切换资源
    resourceClcik(){
      let that = this;
      var ul = document.createElement("div");
      ul.setAttribute("class", "definitionBox");
      ul.setAttribute("id", "definitionList");
      var li2 = document.createElement("div");
      var li3 = document.createElement("div");
      li2.setAttribute("id", "newli1");
      li3.setAttribute("id", "newli2");
      li2.setAttribute("class", "liChild");
      li3.setAttribute("class", "liChild");
      li2.innerHTML = "超清";
      li3.innerHTML = "标清";
      if(this.itemIndex%2 != 0 ){ //如果点击次数是偶数
        if(this.$el.querySelectorAll(".definitionBox")[0]){
        const definitionChild = this.$el.querySelectorAll(".definitionBox")[0];
        //点击清晰度按钮时移除（超清，标清）的父节点definitionBox
        this.vjsControl.removeChild(definitionChild);
        }
      }else if(this.itemIndex%2 != 1){ //如果点击次数是奇数
        ul.appendChild(li3);
        ul.appendChild(li2);
        
        this.vjsControl.appendChild(ul); //添加（超清，标清）父节点
        
     
        let flag = document.getElementById('definitionList');
        // let lng = flag.children;
        // console.log('lng',lng);
        // if(lng.length == '1'){
        //   ul.setAttribute("class", "standard");
        // }
        flag.addEventListener('click',that.ulClcik)
      }
      this.itemIndex++  //点击次数
    },
    ulClcik(e){
        console.log('jidnsdksdks',e.target.innerHTML);
        // console.log('点击切换视频清晰度',this.playerOptions.controlBar);
        if(e.target.innerHTML == '标清'){
          console.log('这是标清',e.target);
          this.playerOptions.sources[0].src = this.wdUrl;
        }else if(e.target.innerHTML == '超清'){
          console.log('这是超清');
          this.playerOptions.sources[0].src = this.wdHighUrl;
        }
    }
  }
};
</script>

<style lang="less" scoped>
.ep-dialog {
  background: rgba(0, 0, 0, 1);
  height: 100vh;
  overflow: hidden;
  .pop-main {
    width: 78%;
    height: auto;
    margin: 2.5% auto;
    padding-bottom: 10px;

    .icon-close {
      text-align: right;
      margin-right: 1rem;
      position: relative;
      top: -38px;
      font-size: 16px;
      color: #000;
      cursor: pointer;
    }
    .input_video {
      width: 100%;
      height: 400px;
      margin: 0 auto;
    }

    

    
  }
}

</style>
<style>
  #vjsControl{
      margin:13px 0 0 10px;
      font-size: 13px;
      cursor:pointer;
      position:relative;
  }
  .definitionBox{
    background-color: rgba(43, 51, 63, 0.69);
    color:#fff;
    text-align: center;
    position: absolute;
    top:-65px;
    width: 60px;
    letter-spacing: 4px;
    /* padding: 3px 3px 2px; */
    line-height: 26px;
    font-size: 14px;
  }

  .liChild:hover{
    background: rgba(255, 255, 255, 0.5);
  }

  .vjs-text-track-display{
    height: 500px;
  }

  .standard{
    background-color: rgba(43, 51, 63, 0.69);
    color:#fff;
    text-align: center;
    position: absolute;
    top:-38px;
    width: 60px;
    letter-spacing: 4px;
    line-height: 26px;
    font-size: 14px;
  }
</style>