<template>
  <div class="pdf-wrapper">
    <div class="docx-preview-wrap">
        <div id="bodyContainer"></div>
    </div>
 </div>
</template>

<script>
import { defaultOptions, renderAsync } from "docx-preview";
console.log(defaultOptions);
export default {
  name: 'listWord',
  components: {
  },
  
  data() {
    return {
      laborList: {},
      docxOptions: {
          className: "kaimo-docx-666", // string：默认和文档样式类的类名/前缀
          inWrapper:  true, // boolean：启用围绕文档内容的包装器渲染
          ignoreWidth: false, // boolean：禁用页面的渲染宽度
          ignoreHeight: false, // boolean：禁止渲染页面高度
          ignoreFonts: false, // boolean：禁用字体渲染
          breakPages: true, // boolean：在分页符上启用分页
          ignoreLastRenderedPageBreak: true, // boolean：在 lastRenderedPageBreak 元素上禁用分页
          experimental: false,
          trimXmlDeclaration: true,
          useBase64URL: false,
          useMathMLPolyfill: false,
          showChanges: false,
          debug: false, // boolean：启用额外的日志记录
      },
      wdUrl:''
    }
  },
  created() {
  },
  mounted(){
    if(sessionStorage.getItem("laborList")){
      this.laborList = JSON.parse(
        sessionStorage.getItem("laborList")
      );
      
    }
    this.wdUrl = this.$route.query.url;
    this.handlePreviewCob(this.wdUrl) //文件地址，
  },
  methods: {
    handlePreviewCob(url) {
      // this.$http.get('api/readDocx',{responseType: 'blob'}).then(res => {//处理响应
      //   console.log("--res--"+res);
        let blob = new Blob([url], {type:"application/vnd.openxmlformats-officedocument.wordprocessingml.document"});
        
        var imgFile = new window.File([blob], Math.random(), { //把bolb转成file文件
          type: blob.type,
        });
        let fr = new FileReader();
        if(fr){
            fr.readAsArrayBuffer(imgFile);
            fr.addEventListener("loadend",(e) => {
                let buffer = e.target.result;
                this.docxRendercob(buffer);
            },false);
        }
      // }).catch(error => {//处理错误
      //   console.log(error);
      // });
    },
    // 渲染docx
    docxRendercob(buffer) {
        let bodyContainer = document.getElementById("bodyContainer");
        renderAsync(
            buffer, // Blob | ArrayBuffer | Uint8Array, 可以是 JSZip.loadAsync 支持的任何类型
            bodyContainer, // HTMLElement 渲染文档内容的元素,
            null, // HTMLElement, 用于呈现文档样式、数字、字体的元素。如果为 null，则将使用 bodyContainer。
            this.docxOptions // 配置
        ).then(res => {
            console.log("res---->", res)
        })
    },
  },
}
</script>


<style lang="less" scoped>
</style>
