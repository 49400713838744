<template>
  <div class="wowo-list">
    <el-row :gutter="24">
      <el-col>
        <div class="bg-purple">
          <el-tabs  class="right-nav-list" v-model="activeNameRight"  @tab-click="handleClick">
            <el-tab-pane
              v-for="(list) in editableTabs"
              :key="list.id"
              :label="list.name"
              :name="list.index"
            >
            <el-collapse v-model="opened"  accordion  @change="selectDisposeCode" >
                <el-collapse-item :class="{'heightStyle':articleList.length>3}"  v-for="(item,index) in documentPreview" :key="index" ref="myCollapseRef" :name="item.id">
                  <template slot="title">
                    <span>{{item.name}}</span>
                  </template>
                  <div   class="right-list-container" v-for="(info,index) in articleList" :key="index">
                    
                    <el-col :span="10" style="padding-left: 0;position: relative;">
                      <img style="width: 100%;" :src="info.cover_img_url" class="right-image" @click="getJumpPdf(info,index,item)">
                    </el-col>
                    <el-col :span="14">
                        <div class="right-text">{{info.title}}</div>
                        <div class="right-time">{{info.created_at}}</div>
                        <div class="num-box">
                          <div v-if="info.type =='2' || info.type =='3'" @click="onDownload(info.file_url,info,item,index)"><img src="@/assets/icon-xz-3.png" />{{info.download_count}}</div>
                          <div><img src="@/assets/icon-gk-1.png" />{{info.browse_count}}</div>
                          <div @click="getLike(index,info)">
                            <img v-if="info.is_like" src="@/assets/icon-dz-3.png" />
                            <img v-else src="@/assets/icon-dz-2.png" />
                            <span>{{info.like_count}}</span>
                        </div>
                        </div>
                    </el-col>
                  </div> 
                  <div v-if="articleList && articleList.length <= 0 || documentPreview && documentPreview.length <= 0" style="text-align: center;">
                    <img src="@/assets/icon-no-order.png" style="width: 260px;" />
                    <p style="margin-top: -20px;">暂无数据~</p>
                  </div>
                </el-collapse-item>
                
            </el-collapse>
            </el-tab-pane>
          </el-tabs>
          <!-- <playVideo v-if='isIncrease' :isShow="isIncrease" :dataList="videoList" @close="isIncrease = false"></playVideo> -->
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
// import playVideo from '@/components/playVideo.vue'
import download from 'downloadjs'
export default {
  name: 'HomePage',
  components: {
    // playVideo
  },
  
  data() {
    return {
      opened:0,
      computedItemHeight:0,
      activeNames: ['0'],
      activeName: 'first',
      currentTime:'',//当前时分秒
      inputText:'',//输入的内容
      answerDate:[],
      videoList:'',
      problemText:'',
      isShow:false,
      rightListData: 0,
      timeoutRight:'',
      activeNameRight:0,//tab 默认展示第0个
      isIncrease:false,
      timer:'',
      editableTabs: [],
      documentPreview:[],
      articleList:[],
      tabbarStatus:[],

      fhCode:'',
      fileNames:'',
    }
  },
  watch: {
    articleList: {
      handler(val, oldval) {
        return this.articleList;
      },
      // 深度观察监听
      deep: true
    }
  },
  mounted(){
    this.getDirectoryList();
    this.fhCode = this.$route.query.fhCode;
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'listpptx') { 
      from.meta.keepAlive = true//缓存页面数据
    }else{
      //清除缓存
      let vnode = this.$vnode
      let parentVnode = vnode && vnode.parent;
      if (parentVnode && parentVnode.componentInstance && parentVnode.componentInstance.cache) {
      var key = vnode.key == null
      ? vnode.componentOptions.Ctor.cid + (vnode.componentOptions.tag ? `::${vnode.componentOptions.tag}` : '')
      : vnode.key;
      var cache = parentVnode.componentInstance.cache;
      var keys  = parentVnode.componentInstance.keys;
        
      if (cache[key]) {
         this.$destroy()
      if (keys.length) {
        var index = keys.indexOf(key)
        if (index > -1) {
          keys.splice(index, 1)
        }
      }
        
        cache[key] = null
      }
      }
    }
    next()
  }, 
  methods: {
    //目录列表
    getDirectoryList(){
      this.$http.get('/api/v1/dir-group/group-list', {
        params: {
          page:'1',//页码
          per_num:'100',//每页显示数
        }
      }).then(res => {//处理响应
        console.log('一级目录res',res);
        if(res.data.list){
          this.editableTabs = res.data.list;
          this.editableTabs.find((item) => {
            if(item.index == '0'){ //如果是第一个
              this.getSubdirectory(item.id)
            }
          })
        }
      })
    },
    handleClick(tab) {//一级目录点击事件
      let tabKey = tab.$vnode.key; //上级目录ID
      this.getSubdirectory(tabKey)
    },
    getSubdirectory(tabKey){
      this.$http.get('/api/v1/dir-group/group-list', {
        params: {
          page:'1',//页码
          per_num:'100',//每页显示数
          pid:tabKey,//上级id
        }
      }).then(res => {//处理响应
        if(res.data.list){
          this.documentPreview = res.data.list;
          
          if(this.documentPreview && this.documentPreview.length > 0){
          let activeId = this.documentPreview[0].id //默认展开第一个二级目录
          if(this.tabbarStatus[tabKey]){//存在激活的二级目录就去展开
            activeId=this.tabbarStatus[tabKey].activeId  //当前一级目录tabs展开的二级id
          }
          this.getArticleList(activeId)
        }
        }
      })
    },
    selectDisposeCode(oId) { //二级目录点击事件
        let obj = {};
        obj = this.documentPreview.find((item) => {
          return item.id == oId;
        });
        if(obj){
          let tabKey = this.editableTabs[this.activeNameRight].id //获取当前tabs的id

          this.tabbarStatus[tabKey]={activeId:obj.id} //保存当前tabs的二级目录激活状态
          

          this.getArticleList(obj.id)
        }
    },
    //文章列表
    getArticleList(dirId){
      this.opened = dirId //激活展开二级目录

      this.$http.get('/api/v1/article/article-list',{
        params: {
          page:'1',//页码
          per_num:'100',//每页显示数
          dir_id:dirId,//上级id
        },
        headers : {
          'Authorization': 'Bearer ' + localStorage.sessionId,
        }
      }).then(res => {//处理响应
        if(res.data.list){
          this.articleList = res.data.list;
        }
      })
    },

    //点赞
    getLike(index,info){
      if(!this.articleList[index].is_like){
        let obj =this.articleList[index]
        obj.is_like=true
        obj.like_count = info.like_count +1
        this.articleList[index] = obj
        this.recordLikes(2,info.id,1)
      }else{
        let obj =this.articleList[index]
        obj.is_like=false
        obj.like_count = info.like_count -1
        this.articleList[index] = obj
        this.recordLikes(2,info.id,2)
      }
    },
    recordLikes(action,id,type){
      let data = {
        id:id,
        type:type || '',//类型 (1点击，2取消)
        action:action,//动作（1浏览，2点赞，3下载）
      }
      this.$http.post('/api/v1/article/add-article-action', data,{
        headers : {
          'Authorization': 'Bearer ' + localStorage.sessionId
        }
      }).then(res => {//处理响应
        console.log('########记录动作',res);
      })
    },
    //下载
    onDownload(fileUrl,item,list,index){
      var str = item.title
      var patt1 = /[\u4e00-\u9fa5]+/g;
      var letters = str.match(patt1).join("");
      
      var fileType = fileUrl.substring(fileUrl.lastIndexOf(".")+1)
      var fileName = `${letters}.${fileType}`
      
      if(fileType != 'url'){
        // this.$message('开始下载');
        // this.$message({
        //   showClose: true,
        //   message: '开始下载',
        //   duration:'0'
        // });

        let _this = this;
        let m = new Promise(function (resolve) {
            _this.$message({
                message: "开始下载",
                center: true,
                type: 'success',
                customClass: "messagebottom"+_this.index,
          });
            resolve();
        });
        m.then(function () {
          let cl = document.getElementsByClassName("messagebottom"+_this.index)[0];
          let t = cl.style.top;
          cl.style.top = 'calc(100vh - '+(parseInt(t)+180)+'px)';
          _this.index++;
        });
        console.log('这是下载',fileUrl,fileName,fileType);
        // download(fileUrl,fileName,fileType)
        download(encodeURI(fileUrl),fileName);

        let timer = setInterval(() => {
          let num =this.articleList[index]
          num.download_count = item.download_count + 1
          console.log('9999',item.id,list.id);
          this.recordLikes(3,item.id,'')
          clearInterval(timer);
        }, 1000)
      }
    },

    //跳转
    getJumpPdf(item,index,list){
      console.log('item',item);
      var fileType = item.file_url.substring(item.file_url.lastIndexOf(".")+1);
      console.log('type',fileType);
      this.isIncrease = false;
      if(item.type == '2'){ //视频
        let routeData = this.$router.resolve({ path: '/playVideo2',
        query:{
            url:item.file_url,
            high_url:item.high_file_url,
            title:item.title,
            type:fileType
          }
        });
        window.open(routeData.href, '_blank');
      }else if(item.type == '3'){ //文档
        let routeData = this.$router.resolve({ path: '/listpptx',
        query:{
            id:item.id,
            url:item.file_url,
            title:item.title,
            type:item.type
          }
        });
        window.open(routeData.href, '_blank');
      }else{ //跳转链接
        window.open(item.target)
      }
      let jumpTimer = setInterval(() => {
        let broNum =this.articleList[index]
        broNum.browse_count = item.browse_count + 1
        this.recordLikes(1,item.id,'')
        clearInterval(jumpTimer);
      }, 1000)
    }
  },
}
</script>

<style lang="less" scoped>

.wowo-list{
  padding: 0 10px;
}
.pop-left-item-top{
    .pop-left-header{
      background: #3179E7;
      padding: 15px 20px;
      .header-title{
        display: flex;
        color:#fff;
        align-items: center;

        .header-title-logo{
           width: 30px;
           height: 30px;
           vertical-align: middle;
        }
      }
    }
  }
  .grid-content{
    background: #e6e6e6a8;
    .pop-left-item-conter{
        .pop-left-subtitle{
          margin: 0 0 10px 20px;
          color:#a7a7ac;
          font-size: 13px;
          padding-top: 20px;
        }
      .pop-left-middle{
        overflow: hidden;
        height: calc(100vh - 36.5vh);
        overflow-y:scroll; 
        padding-bottom: 10px;
        
        .list-box{
          background: #fff;
          border-radius: 10px;
          width: 40vw;
          margin-left: 20px;
          padding: 20px 16px 10px;
          .list-subtitle{
            color:#555756;
            padding-bottom: 10px;
          }
          .pop-left-middle-list{
              padding: 12px 0;
              color:#7b8284;
              font-size: 13px;
              border-bottom:1px solid #ccccccb0;

              .list-text{
                display: flex;
                justify-content: space-between;
                i{
                  margin-top: 3px;
                }
              }
            }
            .pop-left-middle-list:last-child{
              border-bottom: none;
            }
        }
      }
      .pop-left-middle::-webkit-scrollbar {
        width: 5px;
        height: 2vw;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        background: #F1F1F1;
      }
      .pop-left-middle::-webkit-scrollbar-thumb {
        background-color: #D0D0D0;
        background-clip: padding-box;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        min-height: 20px;
      }
    }
  }

  .bg-purple{
    .right-list-container{
      overflow: hidden;
      border-bottom: 1px solid #ccccccb0;
      padding-bottom: 16px;
      margin-bottom: 15px;
      margin-top: 20px;

      
      .right-image{
        height: 100px;
        cursor: pointer;
        
      }
      .right-image-time{
        position: absolute;
        right: 0;
        bottom: 0;
        color: #fff;
        margin-right: 23px;
        margin-bottom: 10px;
        font-size: 12px;
      }
      .right-text{
        font-size: 15px;
        overflow:hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow:ellipsis;
      }
      .right-time{
        color: #BABABA;
        font-size: 13px;
        padding: 12px 0 10px;
      }
      .num-box{
        color: #BABABA;
        margin: 6px 0;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3,1fr);
        cursor: pointer;
        img{
          width: 20px;
          height: 20px;
          vertical-align: top;
          margin-right: 6px;
        }

      }
    }

    .right-list-container:last-child{
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 0;
    }
    
  }
  
  .right-icon{
    position: absolute;
    right: -35px;
    top: 2px;
    color:#a69d9d;
    font-size: 18px;
  }
  .inputContent{
    background: #95EC69;
    padding: 8px 10px;
    border-radius: 8px;
    float: right;
    margin-right: 20px;
    position: relative;
    font-size: 13px;
  }
  .inputContent::after{
    content:"";
    position: absolute;
    right:0;
    top: 11px;
    margin-right: -9px;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 10px solid #95EC69;
  }

  .answer-content{
    background: #fff;
    padding: 8px 10px;
    border-radius: 8px;
    float: left;
    margin-right: 20px;
    position: relative;
    font-size: 13px;
  }

  .answer-content::after{
    content:"";
    position: absolute;
    left:0;
    top: 11px;
    margin-left: 9px;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-right: 10px solid #95EC69;
  }
  .clearfix{
    overflow: hidden;
  }

  .right-nav-list{
    overflow: hidden;
  }
  /deep/ .el-message{
    top:0 !important;
    bottom:10px !important;

  }
</style>
<style>
  .right-nav-list .el-tabs__header .el-tabs__item,.right-nav-list .el-tabs__item{
    height: 50px;
    line-height: 50px;
    text-align: center;
    position: relative;
    padding: 0 40px;
   

  }
  .right-nav-list .el-tabs__header{
    margin-bottom: 12px;
  }
  .right-nav-list .el-tabs__header .el-tabs__item::after{
    content:"";
    width: 1px;
    display: inline-block;
    height: 18px;
    vertical-align: middle;
    background: #ccccccb0;
    position: absolute;
    left: 99%;
    top: 16px;
  }
  .right-nav-list .el-tabs__header .el-tabs__item.is-active,.right-nav-list .el-tabs__header .el-tabs__item:hover{
     color:#3179E7;
  }
  .right-nav-list .el-tabs__header .el-tabs__active-bar{
    background: #3179E7;
  }

  .right-nav-list .el-collapse-item__content{
    margin: 15px 0 0;
  }

  .right-nav-list .el-icon-arrow-left,.right-nav-list .el-icon-arrow-right{
    font-size: 18px;
    vertical-align: middle;
    margin-top: 5px;
  }
  .wowo-list .el-textarea__inner{
    border:none;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    resize:none;
  }

  /* .wowo-list .heightStyle .el-collapse-item__wrap{
    overflow: hidden;
    height: calc(100vh - 36.5vh);
    overflow-y:scroll; 
  } */
   
  /*修改竖向下拉框样式**/
  .wowo-list .heightStyle .el-collapse-item__wrap::-webkit-scrollbar,.wowo-list .el-tabs__content::-webkit-scrollbar{
    width: 6px;
    height: 2vw;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    background: #F1F1F1;
  }
  .wowo-list .heightStyle .el-collapse-item__wrap::-webkit-scrollbar-thumb,.wowo-list .el-tabs__content::-webkit-scrollbar-thumb{
    background-color: #D5D3D3;
    background-clip: padding-box;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    min-height: 20px;
  }
  .wowo-list .el-tabs--top .el-tabs__item.is-top:last-child{
    padding-right: 40px;
  }

  .wowo-list .el-tabs__content{
    overflow: hidden;
    height: calc(100vh - 15vh);
    overflow-y:scroll; 
  }

  
</style>
