import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import VueMoment from 'vue-moment';
import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
import router from './router'
import API_CONFIG from "/vue.config.js";
import axios from 'axios';

//API_CONFIG.devServer.proxy["/api"].target
// axios.defaults.baseURL = '/api';
/* 配置axios */
axios.defaults.baseURL = 'https://help-docs-api.qlx.work'

axios.interceptors.request.use(
  config => {
//     console.log('判断token',localStorage.sessionId);
//     if (localStorage.sessionId) { //判断token是否存在
//       config.headers.Authorization = localStorage.sessionId;  //将token设置成请求头
//     }
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);
// response 拦截器
axios.interceptors.response.use(
	response => {
		const res = response.data
		if (!res || res == null) {
			Vue.prototype.$toast("网络开小差了，请稍后重试");
			return Promise.reject(res)
		} else {
			if (res.errcode == 200) {
				return res
			} else if (res.errcode == 400) {
				Vue.prototype.$toast("网络连接失败，请刷新");
                isLogin();
			} else {
				Vue.prototype.$toast(res);
				return Promise.reject(res)
			}
		}

	},
	error => {
		console.log('err' + error) // for debug
		// Vue.prototype.$toast("网络开小差了，请稍后重试");
		return Promise.reject(error)
	}
);
Vue.prototype.$http = axios;
isLogin();

function isLogin() { //登录接口
	axios.post('https://help-docs-api.qlx.work/api/v1/auth/client-login').then(res => {//处理响应
		console.log('这是登录',res); 
		if(res.data.token){
			localStorage.setItem('sessionId', res.data.token)
		}
	}).catch(err=>{})
}

Vue.use(ElementUI);
Vue.use(VueMoment)
Vue.use(VideoPlayer)
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
