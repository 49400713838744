<template>
  <div class="wowo-list2">
    <el-row :gutter="24">
      <el-col :span="24" style="padding: 0;">
        <div class="grid-content bg-purple">
             <div class="pop-left-item-top">
                 <div class="pop-left-header">
                     <div class="header-title">
                        <el-col :span="1" style="padding: 0;text-align: center;"><img src="@/assets/icon-logo.png" class="header-title-logo"/></el-col>
                        <el-col :span="4" style="padding-left: 0;"><div>沃沃小助手</div></el-col>
                     </div>
                 </div>
             </div>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="24">
      <el-col :span="16">
        <div class="grid-content bg-purple">
             <div class="pop-left-item-conter">
                 <div class="pop-left-subtitle">沃沃小助手 &nbsp; {{currentTime}}</div>
                 <div class="pop-left-middle">
                    <div class="list-box">
                      <div class="list-subtitle">猜你想问</div>
                      <el-tabs v-model="activeName" @tab-click="handleClick">
                        <el-tab-pane label="常见问题" name="first">
                          <div class="block">
                            <el-carousel height="240px" arrow="never" trigger="click" :autoplay="false">
                              <el-carousel-item v-for="(item,index) in presaleslist" :key="index">
                                <div class="pop-left-middle-list" v-for="(info,infoIndex) in item.list" :key="infoIndex">
                                      <div class="list-text">{{infoIndex + 1}}.&nbsp;{{info.name}}<i class="el-icon-arrow-right"></i></div>
                                </div>
                              </el-carousel-item>
                            </el-carousel>
                          </div>
                        </el-tab-pane>
                        <el-tab-pane label="玩转西溪" name="second">玩转西溪</el-tab-pane>
                        <el-tab-pane label="其他问题" name="third">其他问题</el-tab-pane>
                      </el-tabs>
                    </div>
                    <div style="padding:20px 0 20px 18px;" class="clearfix">
                      <el-col :span="3" v-for="(o, index) in peripheryDate" :key="index" style="padding-left: 5px;padding-right: 5px;">
                        <el-card :body-style="{ padding: '0px' }">
                          <img style="width: 100%;height: 80px;" :src="o.img" class="image">
                          <div style="padding: 10px 0 12px;font-size: 13px;text-align: center;color:#303133;font-weight: 500;">
                            <span>{{o.text}}</span>
                          </div>
                        </el-card>
                      </el-col>
                    </div>

                    <div v-if="isShow && inputText" class="inputContent" >{{problemText}}</div>

                    <div class="answer-content" v-for="(item,index) in answerDate" :key="index">
                        <div>{{item.text}}</div>
                    </div>
                 </div>
             </div>
        </div>
        <div class="clearfix">
          <img src="@/assets/icon-wjx-6.png" style="height: 20px;padding: 10px 0 0 14px;"/>
          <el-input type="textarea" :rows="2" v-model="inputText" placeholder="很高兴为您服务，情描述您的问题"></el-input>
          <el-button style="float: right;">发送</el-button>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="bg-purple">
          <!-- <el-tabs  class="right-nav-list" v-model="activeNameRight"  @tab-click="handleClick">
            <el-tab-pane
              v-for="(info) in editableTabs"
              :key="info.id"
              :label="info.name"
              :name="info.index"
            >
            <el-collapse v-model="opened"  accordion  @change="selectDisposeCode">
                <el-collapse-item   v-for="(item,index) in documentPreview" :key="index" :name="item.id">
                  <template slot="title">
                    <span>{{item.name}}</span>
                  </template>
                  <div  class="right-list-container" v-for="(info,index) in articleList" :key="index">
                    
                  
                    <el-col :span="10" style="padding-left: 0;position: relative;">
                        <img style="width: 100%;" :src="info.cover_img_url" class="right-image" @click="getJumpPdf(info,index,item)">
                        
                    </el-col>
                    <el-col :span="14">
                        <div class="right-text">{{info.title}}</div>
                        <div class="right-time">{{info.created_at}}</div>
                        <div class="num-box">
                          <div @click="onDownload(info.file_url,info,item)"><img src="@/assets/icon-xz-3.png" />{{info.download_count}}</div>
                          <div><img src="@/assets/icon-gk-1.png" />{{info.browse_count}}</div>
                          <div @click="getLike(index,info)">
                            <img v-if="info.is_like" src="@/assets/icon-dz-3.png" />
                            <img v-else src="@/assets/icon-dz-2.png" />
                            <span>{{info.like_count}}</span>
                        </div>
                        </div>
                    </el-col>
                  </div> 
                  <div v-if="articleList && articleList.length <= 0" style="text-align: center;">
                    <img src="@/assets/icon-no-order.png" style="width: 260px;" />
                    <p>暂无数据~</p>
                  </div>
                </el-collapse-item>
                
            </el-collapse>
            </el-tab-pane>
          </el-tabs>
          <playVideo v-if='isIncrease' :isShow="isIncrease" :dataList="videoList" @close="isIncrease = false"></playVideo> -->
          <div>
            <div id="iframeBox"></div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
// import playVideo from '@/components/playVideo.vue'
import download from 'downloadjs'
export default {
  name: 'HomePage',
  components: {
    // playVideo
  },
  
  data() {
    return {
      activeNames: ['0'],
      activeName: 'first',
      currentTime:'',//当前时分秒
      inputText:'',//输入的内容
      answerDate:[],
      videoList:'',
      problemText:'',
      isShow:false,
      rightListData: 0,
      timeoutRight:'',
      activeNameRight:'0',//tab 默认展示第0个
      isIncrease:false,
      timer:'',
      editableTabs: [],
      presaleslist:[
         {text:'采购或申请测试',list:[{name:'智住操作视频'},{name:'区票价'},{name:'优惠政策'},{name:'景区电话'},{name:'开闭园时间'}]},
         {text:'招投标|资质',list:[{name:'44444'},{name:'5555'},{name:'666'}]},
         {text:'参数|差异对比',list:[{name:'7777'},{name:'8888'},{name:'999'}]},
      ],
      afterSaleslist:[
         {text:'自助提单'},
         {text:'维保|资质|证书'},
         {text:'查询返修进度'},
         {text:'查询产品安全状态'},
         {text:'渠道认证学习考试'},
         {text:'社区账号&权限'},
         {text:'社区S豆FAQ'},
      ],
      peripheryDate:[
        {img:'https://img2.baidu.com/it/u=3089456875,3715235379&fm=253&fmt=auto&app=138&f=JPEG',text:'杭州西溪宾馆'},
        {img:'https://img1.baidu.com/it/u=624792125,331972384&fm=253&fmt=auto&app=138&f=JPEG',text:'西溪豪假酒店'},
        {img:'https://img0.baidu.com/it/u=1713263066,2572716994&fm=253&fmt=auto&app=138&f=JPEG',text:'杭州喜来登'},
        {img:'https://img2.baidu.com/it/u=3219435629,4248079746&fm=253&fmt=auto&app=138&f=JPEG',text:'西溪悦榕主'},
      ],
      documentPreview:[],
      articleList:[],
    }
  },
  computed: {
      opened() {
          return this.documentPreview.map((i) => {
            return this.documentPreview[0].id;
          });
      }
      
  },
  created() {
    this.currentTime=this.$moment().format('HH:mm:ss')
  },
  mounted(){
    this.getDirectoryList();

    console.log('进来测试也');
    this.wdUrl = 'http://ts-help-docs-web.qlx.work/';
    console.log('5555',this.wdUrl);
    this.wdId = this.$route.query.wdId;
    // this.wdUrl = 'https://microred.oss-cn-hangzhou.aliyuncs.com/micro-red-health-app/1653037004631553459.pdf'
    this.wdType = this.wdUrl.substring(this.wdUrl.lastIndexOf(".")+1);//截取文件后缀 pdf,doc,docx
    this.preview(this.wdType,this.wdUrl)
    
    //1、判断浏览器是否支持popstate
    if (window.history && window.history.pushState) {
        console.log('支持popstate')
        history.pushState(null, null, document.URL);
        window.addEventListener('popstate', this.indexGoBack, false);//false阻止默认事件
    }
  },
  //2、页面销毁时，取消监听。否则其他vue路由页面也会被监听  
  destroyed(){
      window.removeEventListener('popstate', this.indexGoBack, false);//false阻止默认事件
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'listpptx') { 
      from.meta.keepAlive = true//缓存页面数据
    }else{
      //清除缓存
      let vnode = this.$vnode
      let parentVnode = vnode && vnode.parent;
      if (parentVnode && parentVnode.componentInstance && parentVnode.componentInstance.cache) {
      var key = vnode.key == null
      ? vnode.componentOptions.Ctor.cid + (vnode.componentOptions.tag ? `::${vnode.componentOptions.tag}` : '')
      : vnode.key;
      var cache = parentVnode.componentInstance.cache;
      var keys  = parentVnode.componentInstance.keys;
        
      if (cache[key]) {
         this.$destroy()
      if (keys.length) {
        var index = keys.indexOf(key)
        if (index > -1) {
          keys.splice(index, 1)
        }
      }
        
        cache[key] = null
      }
      }
    }
    next()
  }, 
  methods: {

    preview(valueFormat,url){
      //动态创建iframe
      var iframe = document.createElement('iframe');
      iframe.id = "modifyIframe";
      //先进行类型判断
      // if(valueFormat=='doc'|| valueFormat=='docx'|| valueFormat=='xls' || valueFormat=='xlsx' || valueFormat=='pptx' || valueFormat=='ppt') {//根据后缀判断如果
      //   iframe.src = 'https://view.officeapps.live.com/op/view.aspx?src='+ encodeURIComponent(url);
      //   console.log('src',iframe.src);
      // }else{ //如果是pdf
        iframe.src = url;
      // }
      iframe.width = '100%';
      iframe.height = window.innerHeight - 5 + "px";
      // iframe.height ='100%';
      iframe.style.maxHeight = '1000px';
      let iframeBox = document.getElementById("iframeBox");
      iframeBox.appendChild(iframe);
    },
    //监听返回按钮，销毁iframe
    indexGoBack(){
        this.$router.go(-1)
        let iframe = document.getElementById("modifyIframe");
        iframe.src = "about:blank";
        iframe.contentWindow.document.write('')
        iframe.contentWindow.document.clear();
        iframe.contentWindow.close();
        iframe.parentNode.removeChild(iframe);
        iframe = null;
    },

    //目录列表
    getDirectoryList(){
      this.$http.get('/api/v1/dir-group/group-list', {
        params: {
          page:'1',//页码
          per_num:'100',//每页显示数
        }
      }).then(res => {//处理响应
        console.log('一级目录res',res);
        if(res.data.list){
          this.editableTabs = res.data.list;
          this.editableTabs.find((item) => {
            if(item.index == '0'){ //如果是第一个
              this.getSubdirectory(item.id)
            }
          })
        }
      })
    },
    handleClick(tab) {//点击tab
      // console.log('*******tab',tab.$el.firstElementChild.ariaMultiSelectable);
        let tabKey = tab.$vnode.key; //上级目录ID
        this.getSubdirectory(tabKey)
    },
    getSubdirectory(tabKey){
      this.$http.get('/api/v1/dir-group/group-list', {
        params: {
          page:'1',//页码
          per_num:'100',//每页显示数
          pid:tabKey,//上级id
        }
      }).then(res => {//处理响应
        if(res.data.list){
          console.log('二级目录',res);
          this.documentPreview = res.data.list;
          this.documentPreview.find((list) => {
             if(list.index == '0'){
              this.getArticleList(list.id)
             }
          })
        }
      })
    },
    selectDisposeCode(oId) {
        let obj = {};
        obj = this.documentPreview.find((item) => {
          return item.id == oId;
        });
        if(obj){
          this.getArticleList(obj.id)
        }
    },
    //文章列表
    getArticleList(code){
      console.log('3333',code);
      this.$http.get('/api/v1/article/article-list',{
        params: {
          page:'1',//页码
          per_num:'100',//每页显示数
          dir_id:code,//上级id
        },
        headers : {
          'Authorization': 'Bearer ' + localStorage.sessionId,
        }
      }).then(res => {//处理响应
        console.log("文章接口",res);
        if(res.data.list){
        //   console.log('进来插入数据');
            this.articleList = res.data.list;
        }
      })
    },

    //点赞
    getLike(index,info){
      if(!this.articleList[index].is_like){
        this.recordLikes(2,info.id,'',1)
        this.articleList[index].is_like = true;
        this.articleList[index].like_count=info.like_count +1
      }else{
        this.recordLikes(2,info.id,'',2)
        this.articleList[index].is_like = false
        this.articleList[index].like_count=info.like_count -1
      }
    },
    recordLikes(action,id,ejListId,type){
      console.log('进来点赞',ejListId);
      let data = {
        id:id,
        type:type || '',//类型 (1点击，2取消)
        action:action,//动作（1浏览，2点赞，3下载）
      }
      console.log('点赞data',data);
      this.$http.post('/api/v1/article/add-article-action', data,{
        headers : {
          'Authorization': 'Bearer ' + localStorage.sessionId
        }
      }).then(res => {//处理响应
        console.log('########记录动作',res);
        // if(res && !type){ //除点赞外，重新调查询列表接口
         this.getArticleList(ejListId);
        // }
      })
    },
    //下载
    onDownload(fileUrl,item,list){
      this.recordLikes(3,item.id,list.id,'')
      var str=item.title
      var patt1 = /[\u4e00-\u9fa5]+/g;
      var letters = str.match(patt1).join("");
      var fileType = fileUrl.substring(fileUrl.lastIndexOf(".")+1)
      var fileName = `${letters}.${fileType}`
      if(fileType != 'url'){
        download(fileUrl,fileName)
      }
    },

    //跳转
    getJumpPdf(item,index,list){
      this.isIncrease = false;
      this.recordLikes(1,item.id,list.id,'')
      if(item.type == '2'){ //视频
        this.isIncrease = true;
        this.videoList = item;
      }else if(item.type == '3'){ //文档
        this.$router.push({
          path: '/listpptx',
          query:{
            url:item.file_url,
            type:item.type
          }
        });
      }else{ //跳转链接
        window.open(item.target)
      }
    }
  },
}
</script>

<style lang="less" scoped>
.pop-left-item-top{
    .pop-left-header{
      background: #3179E7;
      padding: 15px 20px;
      .header-title{
        display: flex;
        color:#fff;
        align-items: center;

        .header-title-logo{
           width: 30px;
           height: 30px;
           vertical-align: middle;
        }
      }
    }
  }
  .grid-content{
    background: #e6e6e6a8;
    .pop-left-item-conter{
        .pop-left-subtitle{
          margin: 0 0 10px 20px;
          color:#a7a7ac;
          font-size: 13px;
          padding-top: 20px;
        }
      .pop-left-middle{
        overflow: hidden;
        height: calc(100vh - 36.5vh);
        overflow-y:scroll; 
        padding-bottom: 10px;
        
        .list-box{
          background: #fff;
          border-radius: 10px;
          width: 40vw;
          margin-left: 20px;
          padding: 20px 16px 10px;
          .list-subtitle{
            color:#555756;
            padding-bottom: 10px;
          }
          .pop-left-middle-list{
              padding: 12px 0;
              color:#7b8284;
              font-size: 13px;
              border-bottom:1px solid #ccccccb0;

              .list-text{
                display: flex;
                justify-content: space-between;
                i{
                  margin-top: 3px;
                }
              }
            }
            .pop-left-middle-list:last-child{
              border-bottom: none;
            }
        }
      }
      .pop-left-middle::-webkit-scrollbar {
        width: 5px;
        height: 2vw;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        background: #F1F1F1;
      }
      .pop-left-middle::-webkit-scrollbar-thumb {
        background-color: #D0D0D0;
        background-clip: padding-box;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        min-height: 20px;
      }
    }
  }

  .bg-purple{
    .right-list-container{
      overflow: hidden;
      border-bottom: 1px solid #ccccccb0;
      padding-bottom: 16px;
      margin-bottom: 15px;
      margin-top: 20px;

      
      .right-image{
        height: 100px;
        cursor: pointer;
        
      }
      .right-image-time{
        position: absolute;
        right: 0;
        bottom: 0;
        color: #fff;
        margin-right: 23px;
        margin-bottom: 10px;
        font-size: 12px;
      }
      .right-text{
        font-size: 15px;
        overflow:hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow:ellipsis;
      }
      .right-time{
        color: #BABABA;
        font-size: 13px;
        padding: 12px 0 10px;
      }
      .num-box{
        color: #BABABA;
        margin: 6px 0;
        width: 14vw;
        display: grid;
        grid-template-columns: repeat(3,1fr);
        cursor: pointer;
        img{
          width: 20px;
          height: 20px;
          vertical-align: top;
          margin-right: 6px;
        }

      }
    }

    .right-list-container:last-child{
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 0;
    }
    
  }
  
  .right-icon{
    position: absolute;
    right: -35px;
    top: 2px;
    color:#a69d9d;
    font-size: 18px;
  }
  .inputContent{
    background: #95EC69;
    padding: 8px 10px;
    border-radius: 8px;
    float: right;
    margin-right: 20px;
    position: relative;
    font-size: 13px;
  }
  .inputContent::after{
    content:"";
    position: absolute;
    right:0;
    top: 11px;
    margin-right: -9px;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 10px solid #95EC69;
  }

  .answer-content{
    background: #fff;
    padding: 8px 10px;
    border-radius: 8px;
    float: left;
    margin-right: 20px;
    position: relative;
    font-size: 13px;
  }

  .answer-content::after{
    content:"";
    position: absolute;
    left:0;
    top: 11px;
    margin-left: 9px;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-right: 10px solid #95EC69;
  }
  .clearfix{
    overflow: hidden;
  }

  .right-nav-list{
    overflow: hidden;
  }
</style>
<style>
  .right-nav-list .el-tabs__header .el-tabs__item,.right-nav-list .el-tabs__item{
    height: 50px;
    line-height: 50px;
    text-align: center;
    position: relative;
    padding: 0 40px;
   

  }
  .right-nav-list .el-tabs__header{
    margin-bottom: 12px;
  }
  .right-nav-list .el-tabs__header .el-tabs__item::after{
    content:"";
    width: 1px;
    display: inline-block;
    height: 18px;
    vertical-align: middle;
    background: #ccccccb0;
    position: absolute;
    left: 99%;
    top: 16px;
  }
  .right-nav-list .el-tabs__header .el-tabs__item.is-active,.right-nav-list .el-tabs__header .el-tabs__item:hover{
     color:#3179E7;
  }
  .right-nav-list .el-tabs__header .el-tabs__active-bar{
    background: #3179E7;
  }

  .right-nav-list .el-collapse-item__content{
    margin: 15px 0 0;
  }

  .right-nav-list .el-icon-arrow-left,.right-nav-list .el-icon-arrow-right{
    font-size: 18px;
    vertical-align: middle;
    margin-top: 5px;
  }
  .wowo-list2 .el-textarea__inner{
    border:none;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    resize:none;
  }

  /* .wowo-list .el-collapse-item__wrap{
    overflow: hidden;
    height: calc(100vh - 36.5vh);
    overflow-y:scroll; 
  } */
   
  /*修改竖向下拉框样式**/
  /* .wowo-list .el-collapse-item__wrap::-webkit-scrollbar {
    width: 6px;
    height: 2vw;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    background: #F1F1F1;
  }
  .wowo-list .el-collapse-item__wrap::-webkit-scrollbar-thumb {
    background-color: #D5D3D3;
    background-clip: padding-box;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    min-height: 20px;
  } */
</style>
